import React from 'react'
import './team.css';

export default function teamsection() {
  return (
    <>
    <div className='card-container'>
        <div className="col-sm-6 col-lg-3 my-auto">
            <div className="box shadow-sm p-4">
              <div className="image-wrapper mb-3">
                <img className="img-fluid" src="https://i.postimg.cc/Vsd2d1kH/Mohit-Jangid-Mohit-Jangid-1-1.jpg" alt="..." />
              </div>
              <div className="box-desc">
                <b><h4>Mohit Jangid</h4></b>
                <h5>Overall Coordinator</h5>

                <ul id="ul" className="social">
                <li><a href="https://www.linkedin.com/in/mohit-jangid-356054258/" target="_blank" rel="noopener noreferrer" ><i className="fa fa-linkedin"></i></a></li>&nbsp;&nbsp;
                  <li><a href="mailto:mohitj22@iitk.ac.in" target="_blank" rel="noopener noreferrer" ><i className="fa fa-envelope"></i></a></li>
                  &nbsp;&nbsp;
                  <li><a href="/" target="_blank" rel="noopener noreferrer" ><i className="fa fa-instagram"></i></a></li>
                  
                </ul>
              </div>
            </div>
        </div>
        <div className="col-sm-6 col-lg-3 my-auto">
            <div className="box shadow-sm p-4">
              <div className="image-wrapper mb-3">
                <img className="img-fluid" src="https://i.postimg.cc/kMzzSZVM/Bhakti-Bhakti-Sangvikar.jpg" alt="..." />
              </div>
              <div className="box-desc">
                <b><h4>Bhakti Sangvikar</h4></b>
                <h5>Overall Coordinator</h5>

                <ul id="ul" className="social">
                <li><a href="http://www.linkedin.com/in/bhakti-sangvikar" target="_blank" rel="noopener noreferrer" ><i className="fa fa-linkedin"></i></a></li>
                &nbsp;&nbsp;
                  <li><a href="mailto:bhaktij22@iitk.ac.in" target="_blank" rel="noopener noreferrer" ><i className="fa fa-envelope"></i></a></li>
                  &nbsp;&nbsp;<li><a href="" target="_blank" rel="noopener noreferrer" ><i className="fa fa-instagram"></i></a></li>
                  
                </ul>
              </div>
            </div>
        </div>
    </div>
    <a href="/team" class="blog-btn">View Full Team</a> <br/>
    </>
  )
}
