import React from 'react'

export default function Isral_palestine() {
  return (
    <article class="blog-single">
      {/* <!--Hero Section starts--> */}
      <div
        class="page-header page-header--single"
        style={{"background-image": "url(https://i.postimg.cc/SKVh3FSF/191813413-376979517072292-1156238462959470805-n.jpg)"}}
      >
        <div class="row page-header__content">
          <div class="col-full">
            <h1 class="display-1">Israel-Palestine Conflict</h1>
            <ul class="page-header__meta">
              <li class="author">By: <a href="#0">Divyani Gaur & Hitesh Anand</a></li>
              <li class="date">Wednesday, 26 May 2021</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--Hero Section Ends--> */}
      <div class="blog-content-wrap">
        <div class="row blog-content">
          <div class="col-full blog-content__main">
          <blockquote>
                        <p>
                            Disclaimer: The views and the opinion expressed in this article are those of the author and
                            does not necessarily reflect the views and positions of Public Policy and Opinion Cell IITK.
                        </p>
                    </blockquote>
                    <br/>
            <h2>Before World War-1</h2>
            <p class="lead drop-cap">
              A long history of antisemitism (discrimination against Jews as a
              religious, ethnic or racial group) in the Europe and many parts of
              world led to the rise of the Zionism (a feeling of Jewish
              nationalism) which espoused the creation of a Jewish homeland in
              the region of middle east(under the Ottoman Empire).
            </p>
            <p style={{"text-align": "center;"}}>
                <img src="https://lh3.googleusercontent.com/blogger_img_proxy/ANbyha0QEQ6e9YXzuXCOyRt5YBeGhNFY-4LtjB6xNNj-9GY04i786P-Vd_bViSlLT0N8juUad4xtRMEccDGuQXmr_82udewPXbzWROEgwJF_3oh-hjVMAOskl5eQnkoRdcDnfD30DbVyV4crIkvmtjgOykKcpSgdbXRnK0bFWhSRocR-tzGaJaUu0WmSZYV9Ovis0sKNSY_ECcGY68BEkuUf_w=s0-d" alt=""/>
            </p>
            <h2>AFTER WORLD WAR-I</h2>

            <p>
              After the Ottoman empire was defeated in World War 1, the complete
              region of Palestine came under the British mandate. The land
              acquisitions for the settlement of Jewish immigrants led to a
              feeling of dispossession among the Arab population. This led to
              increased conflicts among the Jewish and the Arabs, and the rise
              of Palestinian Nationalism in response to Zionism. Religious riots
              were organized against the Jewish Population in various cities in
              the 1920s and 1930s.
            </p>

            <h2>UN PARTITION PLAN AND FIRST ARAB-ISRAEL WAR</h2>
            <p>
              After World War 2, the Jewish resistance organizations were
              skeptical of the British policies and coordinated armed attacks
              against the British military in addition to the Arab population.
              In 1947, the UN General Assembly proposed a two-state solution:
              “The entire region would be divided into an Arab state and a
              Jewish state. Further, Jerusalem and Bethlehem would be directly
              controlled by the UN". The approval of the partition plan
              contributed to large-scale conflicts and violent clashes between
              Arabs and Jews. After the British mandate over Palestine
              terminated, Israel declared its Independence in 1948. Disappointed
              with this partition plan, the armies of Jordan, Syria, Egypt and
              Iraq waged a war against Israel which was won by Israel and it
              annexed territory beyond the boundaries set by the UN Partition
              plan. The Gaza strip was taken by Egypt and the West Bank by
              Jordan.
            </p>
            <p style={{"text-align": "center;"}}>
                <img src="https://lh3.googleusercontent.com/blogger_img_proxy/ANbyha06-Trj2jCaa5VVzrvwg1uTavU_1H86TT2nWA9Zz5pktekwYHi_-9s7vw1VaWU4mxt3QtCZrODlGiUr-sI67L_ClXhnBdhagNC92ewApJI-MzcFZNO6OfEfXlZgPuj44JvGBG60NYthYAg4blBN_DGMHZwf2gIN_3ioqtsGhB9sYgbSzjGv0pJNSABse28-1amfstvA7pS9kMPxe8g=s0-d" alt=""/>
            </p>
            <h2>PALESTINIAN EXODUS (1948) AND THE SIX-DAY WAR (1967)</h2>
            <p>
              About 700,000 to 750,000 Palestinian Arabs were expelled from the
              area under Israel (Palestinian exodus of 1948). About 856,000 Jews
              fled or were expelled from their homes in Arab countries and most
              of them reached Israel later on. Violence continued throughout
              1950-1967 and resulted into the second Arab-Israeli war (the
              six-day war) in 1967, which was again won by Israel who occupied
              the regions of West Bank, Gaza strip and the Sinai Peninsula.
            </p>
            <p style={{"text-align": "center;"}}>
                <img src="https://ichef.bbci.co.uk/news/976/cpsprodpb/5F57/production/_114370442_04_after_the_1967_war_640-nc.png" alt=""/>
            </p>
            <h2>FORMATION OF PLO AND THE ROLE OF HAMAS</h2>
            <p>
              The Palestine Liberation Organization (PLO) was formed in 1964 and
              officially recognized by the UN in 1974. In 1987, newly formed
              Palestinian organization Hamas started violent uprisings and
              protests against the Israeli occupation of the West Bank and Gaza.
              A series of violent clashes followed from 1987 to 1993(The first
              Intifada) which saw heavy casualties on both sides. Hamas is
              designated as a terrorist organization by the EU and other
              countries like US, Japan, Australia etc. due to its suicide bomber
              attacks, rocket firings from the Gaza strip and multiple attempts
              to derail the peace talks between Israel and PLO. Hamas has openly
              stated through the years that it has encouraged and organized acts
              of terrorism against Israelis over the years. But some countries
              like Iran, Russia, China, Egypt, etc. don’t consider it as a
              terrorist organization. The multiple failed attempts to establish
              an independent Palestinian state led to the Second Intifada
              (2000-2005) which witnessed 1000+ casualties from both sides.
              Rockets fired by Hamas from Gaza and strikes launched by the IDF
              in retaliation still contribute to the violence
            </p>

            <h2>OSLO PEACE PROCESS</h2>

            <p>
              In 1993, Israel and PLO started secret negotiations (Oslo Peace
              Process). PLO gained autonomous authority over various regions in
              the Gaza strip and the West Bank and promised to recognize Israel
              as a separate nation and abstain from terror related activities in
              return. However, this agreement was opposed by the Hamas who was
              carrying out suicide bomber attacks throughout Israel. Some
              radical Jewish groups also opposed this solution. Things went
              downhill after the Israeli PM Rabin was assassinated by an
              extremist Jewish radical which brought the peace negotiations to a
              halt.
            </p>
            <p style={{"text-align": "center;"}}>
                <img src="https://www.aljazeera.com/wp-content/uploads/2020/06/oslomap.jpeg" alt=""/>
            </p>
            <h2>DIVISION OF POWER IN PALESTINE (THE BATTLE OF GAZA)</h2>
            <p style={{"text-align": "center;"}}>
                <img src="https://cdn.statcdn.com/Infographic/images/normal/20001.jpeg" alt=""/>
            </p>
            <p>
              In 2006, Hamas won the Palestinian legislative elections and got
              into conflict with Fatah(2nd largest party after election) leading
              to the Battle of Gaza (2007) which divided the Palestinian power
              into two: the Gaza strip would be ruled by the Hamas and the West
              Bank by the Palestinian National Authority. US and many European
              countries cut all funds to Hamas due to its multiple attempts to
              derail peace talks and violent approach towards Israel such as
              suicide bombings. On the other hand, in the West Bank, Israel
              continued to annex its settlements in the region.
            </p>
            <h2>PLIGHT OF GAZA</h2>

            <p>
              As reported by World Report 2020, the Israeli government enforced
              several discriminatory restrictions on Palestinians’ human rights
              . It included restricting the movement of people , as strict as
              that a general travel ban excludes only what Israel calls
              “exceptional humanitarian cases,” meaning mostly medical patients
              and their companions, as well as prominent businesspersons with
              influence can move into and out of the Gaza Strip; and
              facilitating the transfer of Israeli citizens to settlements in
              the occupied West Bank . Israel’s closure of its borders with
              Gaza, was made even worse by Egyptian restrictions, and limits
              access to educational, economic and other opportunities, medical
              care, clean water and electricity for the nearly 2 million
              Palestinians living there . As much as 80% of Gaza’s population
              depend on humanitarian aid .The Palestinians’ homes and other
              purpose buildings that are destroyed by the rocket firing of
              Israel (unlawful activity under international law) are not even
              reconstructed as the supply of materials is restricted by Israel ,
              leaving a large population of Palestine homeless
            </p>

            <h2>TENSIONS BEFORE ESCALATION</h2>
            <p style={{"text-align": "center;"}}>
                <img src="https://cdn.statcdn.com/Infographic/images/normal/16516.jpeg" alt=""/>
            </p>
            <p>
              Israel’s discriminatory policy is also quite evident from the
              events that followed this year on the night of 13 April, the
              beginning of the Muslim holy month of Ramadan , an Israeli police
              squad raided the Haram al Sharif and, brushing aside mosque
              attendants, severed the loudspeaker cables used to broadcast the
              al-Aqsa muezzin's ritual call to prayer so that the speech being
              delivered by Israeli President Reuven Rivlin below, at the Western
              Wall, for Memorial Day in Israel would not be disturbed. At the
              same time, they blocked off access to the Damascus Gate where
              Muslim worshippers gather during the holiday which were however
              removed two weeks later in the wake of the protests that followed.
              On the first Friday of Ramadan, Israel imposed a 10,000-person
              limit on the prayers happening at the mosque , turning down a
              large number of Palestinian worshippers from al-Aqsa. On 22 April,
              the far-right Jewish supremacist group Lehava held a march through
              Jerusalem chanting "death to Arabs"
            </p>
            <p>
              Itamar Ben-Gvira leader in the Israeli far-right Otzma Yehudit
              party , visited Sheikh Jarrah shortly before the clashes began,
              where he said that the houses belonged to Jews and told police to
              "open fire" on protesters, here it must also be noted that Israel
              has already been facilitating the establishment of its citizen in
              occupied West Bank since a long time devoiding the Arabs their
              homes , which is again the violation of International humanitarian
              la . Agence France-Presse (a French news portal) reported that
              Israeli settlers had been seen in Sheikh Jarrah openly carrying
              assault rifles and revolvers leading up to the clashes. These
              events and many more events that followed stressed the already
              stressed situation even more
            </p>
            <h2>THE CRISIS TRIGGERED</h2>
            <p>
              The crisis was triggered on 6 May this year , when Palestinians
              protested in East Jerusalem over an anticipated decision of the
              Supreme Court of Israel on the eviction of six Palestinian
              families in Sheikh Jarrah. Under international law, the area, that
              has been under the seize of Israel, is a part of the Palestinian
              territories. The protests quickly turned into violent
              confrontations between Jewish and Palestinian protesters . As the
              violence escalated, Israeli police stormed the compound of the
              al-Aqsa Mosque and used tear gas, rubber bullets and stun grenades
              against ‘stone-throwing’ Palestinians .The confrontations occurred
              ahead of a planned Jerusalem Day march by far-right Jewish
              nationalists that was later canceled. More than 300 people were
              injured, mostly Palestinians, drawing international criticism. The
              Supreme Court ruling was then delayed for 30 days as the attorney
              general of Israel, Avichai Mandelblit, sought to reduce tensions
            </p>
            <p style={{"text-align": "center;"}}>
                <img src="https://ichef.bbci.co.uk/news/976/cpsprodpb/15146/production/_118424368_mediaitem118424367.jpg" alt=""/>
            </p>
            <p>
              The ongoing political crisis in Israel as well as in Palestine
              fueled the crisis even more . The 2021 Palestinian legislative
              election for the Palestinian Legislative Council, originally
              scheduled for 22 May , was indefinitely postponed by Palestinian
              President Mahmoud Abbas. There is a divide amongst the
              Palestinians even in case of their stand on Hamas, as Hamas has
              been accused of detaining as many as 1000 Palestinians
              demonstrating against high cost of living , outright accusing them
              of destroying “revolutionary unity” and “brotherhood”. However ,
              due to its increasing popularity as it resorts more towards the
              political approach , Hamas was expecting to do well in the
              elections. Hamas called the move a "coup", and some Palestinians
              even believe Abbas had delayed the election to avoid political
              defeat for his party Fatah. This encouraged Hamas even more to
              resort to military confrontation rather than diplomatic tactics
            </p>

            <h2>PREVAILING POLITICAL CRISIS</h2>

            <p>
              Israel saw four inconclusive elections which has left the country
              functioning under a “caretaker” government , the cause of the
              political crisis in Israel. Prime Minister Benjamin Netanyahu had
              been trying to persuade several extreme-right politicians to form
              a coalition but the presence of right-wing Israeli politicians
              like Ben-Gvir and King contributed to make that difficult and add
              more to the crisis , which eventually led Netanyahu to stimulate a
              crisis to build support for his leadership, and thus allowed
              tensions to rise in Jerusalem. This is quite evident from the fact
              that when Hamas called for a ceasefire on 13 May by Hamas, but the
              proposal was rejected by Israeli prime minister Benjamin Netanyahu
            </p>
            <h2>CASUALTIES AND INFRASTRUCTURAL DAMAGE</h2>
            <p>
              On 10 May, Hamas gave Israel an ultimatum to withdraw security
              forces from the Temple Mount complex and Sheikh Jarrah. On the
              same day, Hamas and Palestinian Islamic Jihad began firing rockets
              into Israel from the Gaza Strip, hitting multiple residences and a
              school and as a response Israel began a campaign of airstrikes
              against Gaza. In Gaza, according to a UN estimate, there was
              additional damage to 260 buildings , 53 schools and by May 18 , 17
              hospitals and clinics in Gaza were damaged .The Israeli strike on
              the Rimal clinic also shut down the only COVID-19 laboratory in
              the Strip, rendering further screening for the pandemic impossible
            </p>
            <p style={{"text-align": "center;"}}>
                <img src="https://lh6.googleusercontent.com/_FE7gBbWbXa3awSmyG2gQjWru7fX1frHe_avSp7Y5_Yb5u3VJE-6CMIm2ugUXqL3RyIutT3JBx2LaUzuwYehcZS-y0YdCiG9JlDM5XqB65IGIKZDwmPAfzZH0wGrjT5-8kcwfvYV" alt=""/>
            </p>
            <p>
              In its defense, the Israel Defense Forces said , on May 11, that
              at least 15 of the Palestinian casualties were members of Hamas,
              and also said that some Palestinian civilian casualties were
              caused by errant rocket launches within the Gaza Strip. It is
              disputed whether some of the first victims were as a result of an
              Israeli airstrike or an errant Palestinian rocket , as out of the
              4,360 missiles fired by Hamas 680 hit their own territory in Gaza
              after falling short
            </p>
            <p>
              As of 19 May, at least 72,000 Palestinians have been displaced.
              According to reports, 248 people have been killed in Gaza as of 21
              May , including 66 children, and more than 1,900 others have been
              wounded ,while 13 deaths in Israel were reported, including two
              children, one Indian woman and two Thai men living and working in
              Israel
            </p>
            <p>
              Israel Defense Forces saw international criticism forthcoming when
              it targeted the al-Jalaa building in Gaza, which housed Al Jazeera
              and Associated Press journalists, and a number of other offices
              and apartments. The building was hit by at least 4 missiles
              ,though IDF warned a the owner of the building an hour before the
              attack , advising the occupants to evacuate the building. Israeli
              Forces cited the reason of the attack to the presence of “Hamas
              military intelligence” , however , The Associate Press denied the
              presence of any such force. The attack was condemned by The
              Committee to Protect Journalists .Gary Pruitt, CEO of Associated
              Press, said the news agency was "shocked and horrified that the
              Israeli military would target and destroy the building housing
              AP's bureau and other news organizations in Gaza" and that "the
              world will know less about what is happening in Gaza because of
              what happened today .Adding to this, Palestinian journalist Yusuf
              Abu Hussein was also killed in an Israeli airstrike in his home on
              19 May, prompting outcry from the International Federation of
              Journalists
            </p>
            <h2>RESPONSE FROM THE WORLD</h2>
            <p>
              Coming to the response to the crisis by other countries, on 18
              May, France, with Egypt and Jordan, announced the filing of a
              United Nations Security Council resolution for a ceasefire. A
              ceasefire between Israel and Hamas was enacted on 21 May 2021,
              ending 11 days of fighting with both sides claiming victory. The
              foreign ministers of Germany, the Czech Republic, and Slovakia
              visited Israel to express support and solidarity with Israel .
              U.S, President Joe Biden stated over a telephone call to President
              Benjamin Netanyahu that , "Israel has a right to defend itself
              when you have thousands of rockets flying into your territory.” On
              19 May , U.S. President Joe Biden again held a phone call with
              Prime Minister Benjamin Netanyahu, expressing to his Israeli
              counterpart that "he expected a significant de-escalation today on
              the path to a ceasefire". The Arab countries unanimously condemned
              the violence, but took little action on their part. The New York
              Times cited the reason for this as the effort to maintain
              diplomacy with Israel on Iran, while trying to avoid outrage from
              their own citizens
            </p>
            <p>
              As of India, India’s permanent representative to the United
              Nations, T S Tirumurti, made a carefully crafted statement at the
              UN Security Council “open debate” on the conflict, which diverged
              from its usual stance from being unequivocally pro-Palestine for
              the first four decades, to balancing act , trying to maintain
              diplomacy with Israel. Since 1992, the India-Israel relationship
              continued to grow, mostly through defence deals, and in sectors
              such as science and technology and agriculture, while India’s
              solidarity with Palestine during initial years can be cited to
              India’s own Partition along religious lines . Later, India’s
              energy dependence on the Arab countries also became a factor, as
              did the sentiments of India’s own Muslim citizens
            </p>

            <h2>CEASEFIRE AND AFTER EFFECTS</h2>
            <p>
              As of 20 May, Israel and Hamas agreed to cease hostilities. A
              ceasefire deal was brokered by Egypt, Qatar, and the United
              Nations between Israel and Hamas and was enacted on 21 May 2021,
              ending 11 days of fighting, both sides claiming victory. After the
              ceasefire deal, there were no substantive violations of the
              ceasefire other than a minor encounter at Al-Aqsa Mosque,
              throughout the day on 21 May. Biden later described the deal as
              "mutual" and "unconditional" and expressed his belief that both
              sides deserved to live in safety.
            </p>
          </div>
        </div>
      </div>
    </article>
  )
}
