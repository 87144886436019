import React from 'react'
import './article.css'
export default function () {
  return (
    <article class="blog-single">
        {/* <!--Hero Section starts--> */}
        <div class="page-header page-header--single" style={{"background-image": "url(https://i.postimg.cc/zv3G8g5x/Whats-App-Image-2023-03-18-at-2-16-05-AM.jpg)"}}>
        <div class="row page-header__content">
                <div class="col-full">
                    <h1 class="display-1">
                       Adani Group - The Hindenburg Report
                    </h1>
                    <ul class="page-header__meta">
                        <li class="author">By: <a href="#">Suryansh Gaur, Reetika Gupta, Shreyansh Kesharwani, Manuja, Siddharth Garg</a></li>
                    </ul>
                </div>
            </div>
        </div>
        {/* <!--Hero Section Ends--> */}
        <div class="blog-content-wrap">
            <div class="row blog-content">
                <div class="col-full blog-content__main">
                    <blockquote>
                        <p>
                            Disclaimer: The views and the opinion expressed in this article are those of the author and
                            does not necessarily reflect the views and positions of Public Policy and Opinion Cell IITK.
                        </p>
                    </blockquote>
                    <br/>
                    <h2>Hindenburg Research Organization</h2>
                    <p>Nathan Anderson, who founded the company, worked as a Chartered Financial Analyst who usually identifies companies' risk measures and works to clarify them.
                    The name of the company is based on the Hindenburg Disaster in 1937. It was a man-made disaster and through this inspiration, Nathan’s target was to identify such man-made economical disasters and scams that can affect the common people.
                    </p>
                    <p>The work of the Hindenburg Research Organization is to predict financial disasters, and overvalued stocks and investigate financial frauds and scams by multinational companies.</p>
                    <p>The organisation earns their profit by short-selling the stocks of the companies they predict. They have an extreme track record of identifying the unusual behaviour that creates chaos. Previously they have hunted down companies like Nikola, Wins Finance, China Metal Resources Utilisation Limited, and Genius Brands International.</p>
                    <p>Recently, they short-sell the stocks of Nikola at 43% which is a very concrete and bold prediction to make.  
                    Till 2017, they short-sell 17 companies.
                    </p>
                    <p>On 24 January 2023, Hindenburg Research Organization released a report titled <i> ‘Adani Group: How The World’s 3rd Richest Man Is Pulling The Largest Con In Corporate History’.</i></p>
                    
                    <h2>Adani Group</h2>
                    <p>Adani group consists of 8 crucial firms which include:
                    </p>
                    <ul>
                        <li>Adani Enterprises(Coal and Mining)</li>
                            <li>Adani Transmission( Power)</li>
                            <li>Adani Green Energy(Solar and Electricity)</li>
                            <li>Adani Total Gas</li>
                            <li>Adani Power</li>
                            <li>Adani Ports</li>
                            <li>Adani Wilmar</li>
                        
                    </ul>
                    

                    <h2>The Hindenburg Report</h2>
                    <p>The allegations associated with the group were sub-categorized into various forms:</p>
                    <ul>
                        <li>Accounting Fraud</li>
                        <li>Stock Manipulation</li>
                        <li>Corruption and theft of taxpayer funds</li>
                        <li>Money Laundering</li>
                        <li>Offshore shell companies</li>
                                                
                    </ul>

                    <h3>Accounting Fraud</h3>
                    <p>These are business scandals which arise from international manipulation of financial statements. These frauds include</p>
                    <ul>
                        <li>Overstating Revenue</li>
                        <li>Failing to record expenses</li>
                        <li>Misstating assets and liabilities</li>
                        <li>Hiding profits and losses</li>
                    </ul>
                    <p>Basic functions meant to cut as checks and balances on the Adani group were lacking.
                    For example:</p>
                    <ul>
                        <li>Roles and changes in the Chief Financial Officer</li>
                        <li>Credible Independent auditor</li>
                        <li>Independent Incorporation Agents</li>
                    </ul>
                    <div style={{"display": "flex;justify-content: center;"}}>
                        <img src="https://i.postimg.cc/bYSbGdhz/Adani-Grp.png" alt=""/>
                    </div>
                    <p>
                        Absence of an experienced and credible auditor team is also an allegation. The auditing firm which regulates the finances of the company consists of 11 employees. Most of them are in their 20s and are considered too inexperienced to be doing the work they do.
                    </p>
                    <p>Shah Dhandaria is alone responsible for auditing two enormous companies i.e  Adani Enterprises and Adani Total Gas. Sources report that CA Shubham Rohatgi, one of the auditors, was 23 years old when he audited the first chain of statements. Similarly Ankit Ajmera, one of the team members was 24 years old.</p>
                    <p>There exist some control weaknesses in the other groups and firms of Adani Group. Investigation states that there are ‘ no clean and qualified opinions’ for the management.</p>

                    <h3>Stock Manipulation</h3>
                    <p>It involves literal manipulation of the stock market for personal gain. Influencing the behaviour of securities with intent to do so.</p>
                    <p>A few known methods include</p>
                    <ul>
                        <li>Pump and Dump: Inflating the stock prices, then selling out the stock while others continue with overvalued stocks.</li>
                        <li>Poop and scoop: Price deflating artificially hence making profits.</li>
                    </ul>
                    <p>Vinod Adani and Dharmesh Doshi are the two peak suspects in stock manipulation.Doshi was caught in hand for being associated with Ketan Parekh, an infamous market manipulator, in stock manipulation.
                    </p>
                    <p> SEBI has prosecuted 70 entities of Adani promoters, for pumping Adani enterprises' stock over the years. The group is still suspected to be associated with Ketan Parekh.</p>
                    <p>Use of Overseas entities to satisfy and get away with India’s rules and regulations for eg. SEBI has rules to limit the minimum public shareholding of 25% to limit insider stock manipulation. 
                    Allegations that SEBI knows the unusual behaviour as said by a Mauritius-based stock manipulator and also that deals are still working with CA Dharmesh Doshi.
                    SEBI reported in 2003  that 34 brokers, sub-brokers and clubs used to manipulate Adani Ent. shares causing a 128% spike in shares.</p>

                    <h3>Offshore Shell companies</h3>
                    <p>Vinod Adani and Rajesh Adani have been running offshore shell companies in UAE and the Caribbean islands. Samir Vora, brother-in-law of Gautam Adani has been alleged to have Mauritius-based entities with no prior background, unfinished past data and no records. Usually, these kinds of companies are made to avoid regular disclosure and jurisdictions. Some of these companies don’t even have a website.</p>

                    <h3>Corruption, Money laundering and theft of taxpayer funds</h3>
                    <p>Adani’s relation with Amicorp, incorporation associated with a 4.5 billion$ 1mdb scam.  
                    Legal cases were filed by the Malaysian anti-corruption commission which states that they are ‘washing away the investor’s money in an unidentifiable flow.’</p>
                    <p>Growmore’s founder Chang Chung Ling is associated with Vinod Adani and is involved in two fraud schemes including various company-based corruption.</p>
                    <p>Adani group was associated with the Iron ore scandal in which the illegal export of Iron was carried out. They’ve been associated with spreading corruption by bribing all levels of the government.
                    </p>

                    <h3>Political Controversies rises</h3>
                    <p>Indian tycoon Gautam Adani's troubles with his conglomerate's stock price have spilled over to his country's politics — his ties to our Prime Minister Narendra Modi have now come under scrutiny.</p>
                    <p>Our prime minister - Modi has been dragged into the kerfuffle — since both he and Adani are from the western Indian state of Gujarat. The prime minister's political opponents say that the leader favours Adani — a claim the Modi government has rejected, per Reuters. Adani himself told a local TV channel on Friday that claims of his rise are thanks to a close friendship with Modi are "baseless," reported the Times of India. "The fact of the matter is that my professional success is not because of any individual leader," said Adani.</p>
                    <p>The Hindenburg scandal is the perfect weapon for the Indian political opposition against PM Modi. Not only is Modi's relationship with Adani coming into question, Modi's growth model is too. This is perfect opportunity for opponent parties.</p>
                    <p>Hence now angry protestors are demanding an investigation into these allegations. Hundreds of Indian opposition party members demonstrated on the streets of New Delhi, hitting out at investments into Adani's firms made by several state-backed companies as a market rout tore through the companies, Reuters reported.
                    </p>

                    <h3>Impact on Indian Economy</h3>
                    <p>A day after the ₹20,000 crore follow-on public offer (FPO) of Adani Enterprises sailed through on Tuesday, shares of the Adani Group’s 10 listed firms plunged on Wednesday. The flagship Adani Enterprises crashed more than 25%, as the crisis triggered by the Hindenburg Research report seems to be worsening.</p>
                    <p>The ₹20,000 crore FPO from Adani Enterprises was fully subscribed after the support of high-net-worth individuals (HNIs) and institutional investors. The offer had evoked poor response from retail investors and even employees of the group.</p>
                    <p>In a related development, global brokerage firm Credit Suisse, on Wednesday, assigned a zero-lending value for the bonds sold by three group firms — Adani Ports and SEZ, Adani Green Energy and Adani Electricity Mumbai.</p>
                    <p>The combined market value of the group shares has fallen by more than 35% in just five trading sessions, wiping out an estimated ₹7.4 lakh crore from the combined market capitalisation of the group.</p>
                    <p>Wednesday’s rout saw its cement stocks with ACC fall by 6.2 % and Ambuja Cement down by as much as 16.7% while the recently-acquired NDTV’s stock declined by around 5%. The group had acquired ACC and Ambuja from Switzerland’s Holcim Group.</p>
                    <p>Meanwhile, according to the Bloomberg Billionaires Index, Gautam Adani, who was ranked third in the world’s richest billionaire list, has now dropped to 15th position.</p>                    
                </div>
            </div>
        </div>
    </article>
  )
}
