// components/Home.js
import React from 'react';

import './eventspage.css';
const Event = () => {
  return (
  <> 
       
        <div className="event" style={{backgroundImage: 'url("https://i.postimg.cc/jSdrmZx3/page.jpg")'}}>
        <div className="background-image1"><div className="flexbox2">
        <div className="containerbox1">
  <h1>IITK MUN</h1><br></br>
  <p>
  IITK MUN is a highlight of the Flagship event Policy Conclave 23,  Unfolded on April 15th & 16th, proving to be a resounding success. Enthusiastic students across disciplines converged for stimulating debate and diplomacy, fostering critical thinking, diplomatic skills , and a deeper grasp of global affairs. The All India Political Parties Meet(AIPPM) committee addressed the escalating issues of unemployment amidst a growing population. Drawing participants form within and beyond the college, the MUN’s triumph was attributed to the diligent efforts of the organizing committee and the lively engagement of the delegates.
  </p>
  <img src="https://picsum.photos/400/250" alt="Description of your image" /><br></br>
  </div><center><div><a href="register.html" className="register-button">
    Register Now
  </a></div></center>
</div>
</div></div>

      </> 
  );
};

export default Event;
