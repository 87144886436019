import React from 'react'

export default function Uniform_Civil_code() {
  return (
    <article class="blog-single">
        {/* <!--Hero Section starts--> */}
        <div class="page-header page-header--single"
            style={{"background-image": "url(https://i.postimg.cc/QxK0jm1K/Uniform-Civil-Code.png)"}}>
            <div class="row page-header__content">
                <div class="col-full">
                    <h1 class="display-1">
                        Uniform Civil Code : Past and Present
                    </h1>
                    <ul class="page-header__meta">
                        <li class="author">By: <a href="#">Anchit Gupta, Siddharth Garg, Shreyansh Kesharwani</a></li>
                    </ul>
                </div>
            </div>
        </div>
        {/* <!--Hero Section Ends--> */}
        <div class="blog-content-wrap">
            <div class="row blog-content">
                <div class="col-full blog-content__main">
                    <blockquote>
                        <p>
                            Disclaimer: The views and the opinion expressed in this article are those of the author and
                            does not necessarily reflect the views and positions of Public Policy and Opinion Cell IITK.
                        </p>
                    </blockquote>
                    <p>Uniform Civil Code is a proposal to create and enforce personal laws that apply to all people
                        equally, regardless of their gender, sexual orientation, or religion. Implementation of a UCC
                        across the nation is one of the contentious promises pursued by the BJP after the construction
                        of Ram Mandir in Ayodhya and the revocation of Article 370 in Jammu & Kashmir. The Shah Bano
                        case in 1985 sparked significant interest in UCC, which has since become a major issue in Indian
                        politics.<br />
                        Recently a private member’s bill to implement the Uniform Civil Code in India was introduced in
                        Rajya Sabha on 9th December by BJP MP Kirodi Lal Meena.<br />
                        This article explores the legal debate over the bill, issues with its implementation, its
                        impact, etc., along with popular views and past history.
                    </p>
                    <h2>What is Uniform Civil Code?</h2>
                    <p>Laws in India can be broadly classified into two divisions: </p>
                    <ol>
                        <li>
                            <b>Public Laws:</b> These laws apply to all equally regardless of religion, gender, and
                            sexual
                            orientation, e.g., the Indian Penal Code, Evidence Act, Income Tax Act, etc.
                        </li>
                        <li>
                            <b>Personal Laws:</b> These laws are not uniformly applicable to all but specifically apply
                            to specific
                            communities; these are guided by the religious scriptures, e.g., Muslim Personal Law, Hindu
                            Personal Law, etc.
                        </li>
                    </ol>
                    <p>In case one does not want to be governed by the personal law related to a particular community,
                        they always have the option to go for "Special Laws." For example, the Special Marriage Act
                        1954, which governs the marriage-related issues of all Indians, irrespective of their religion.
                    </p>
                    <p>The UCC calls for the formulation and implementation of one law for India, which would be
                        applicable to all religious communities in matters such as marriage, divorce, inheritance,
                        adoption, and maintenance. </p>
                    <p>Articles 25-28 of the Indian constitution guarantee religious freedom to Indian citizens and
                        allow religious groups to deal with their own affairs, whereas article 44 of the Indian
                        constitution expects the Indian state to apply directive principles and common law for all
                        Indian citizens while formulating national policies.</p>

                    <p>Implementation of a uniform civil code is one of the earliest and most contentious promises of
                        the ruling Bharatiya Janata Party. It is an important issue considering secularism in Indian
                        politics and remains disputed by left wings, Muslim groups, and other conservative religious
                        group in defense of Sharia and religious customs.</p>
                    <p>The idea behind implementing UCC is to bring formal equality among all citizens of the country,
                        as having different personal laws goes against the right to equality stated in article 14 of the
                        Indian Constitution.</p>
                    <h2>Ambedkar’s view on Uniform Civil Code</h2>
                    <p>Ambedkar said that <em>"There was nothing new about the Uniform Civil Code. There already existed
                            a common civil code in the country except for the areas of marriage, inheritance — which are
                            the main targets for the Uniform Civil Code in the Draft Constitution."</em> Ambedkar also
                        felt that the UCC should be optional and argued that the absence of a UCC would hinder the
                        government's attempts at social reforms.</p>
                    <p>Ambedkar supported the inclusion of UCC, but he was mindful of the serious objections several
                        Muslim members of the Constituent Assembly had to it. Ambedkar had also hoped no government
                        would exercise its power to enact a uniform code in a way that would <em>"provoke the Muslim
                            community to rise in rebellion."</em></p>

                    <p>On 23 November 1948, Ambedkar sought to reassure the minority members of the Constituent Assembly
                        during a discussion on the UCC. He said, <em>"It [Article 35] does not say that after the Code
                            is framed the State shall enforce it upon all citizens merely because they are
                            citizens."</em> He also said it is <em>"perfectly possible"</em> that the future Parliament
                        may, in the beginning, make a provision that the Code shall apply only to those who declare that
                        they are <em>"prepared to be bound by it." In other words, initially, "the application of the
                            Code may be purely voluntary."</em></p>

                    <p>In other words, Ambedkar felt that a UCC could be introduced initially as a voluntary code and
                        that Parliament would certainly not force citizens to follow it.</p>

                    <p>Let's discuss one state having UCC before moving to what happened in recent. </p>
                    <h2>Uniform Civil Code in Goa</h2>
                    <p>
                        The only state in India that has Uniform Civil Code is Goa. Goa has a common family law
                        irrespective of religion, caste, or gender. It means that Hindus, Muslims, Christians, and
                        everyone is bounded by the same law related to marriage, succession, and divorce.
                    </p>
                    <p>In 1867, Portugal introduced the Portuguese Civil Code and in 1869 to Portugal's overseas
                        provinces, and Goa was a province under Portugal then. This civil code continues to be in force
                        in Goa because of Section 5(1) of Goa, Daman and Diu Administration Act, 1962, which says:
                        <em>"all laws in force immediately before the appointed day (the day Goa was liberated on
                            December 19, 1961) in Goa, Daman and Diu or any part thereof shall continue to be in force
                            therein until amended or repealed by a competent legislature or other competent
                            authority."</em> This civil code is considered as a Uniform Civil Code. This code has been
                        replaced in its origin country but is still in force in Goa.
                    </p>




                    <h3><em>What's wrong?:</em></h3>
                    <ol>
                        <li>
                            The law is not so uniform for Roman Catholics and non-Catholics.
                            While the first signature is common across all religions, the church ceremony is considered
                            a valid 'second signature' for Catholics. The law recognizes a Church marriage as valid for
                            civil purposes. For non-Catholics, however, both signatures are required to be before a
                            civil registrar. Similarly, a divorce granted by the ecclesiastical (Church) authorities is
                            treated as valid for civil purposes, while non-Catholics have to secure a divorce before a
                            civil court.

                        </li>
                        <li>
                            The law also doesn't recognize bigamy or polygamy, including for Muslims but grants an
                            exception to a Hindu man to marry once again if his wife doesn't conceive a child by the age
                            of 21 or a male child by the age of 30.

                        </li>
                    </ol>

                    <h3><em>Problems to extend for the entire India: </em></h3>
                    <p>In India, many religions have different sets of personal laws, such as Hindu and Christian laws.
                        Organizations such as the All India Muslim Personal Law Board are also working to protect these
                        personal laws. This may seem to have a uniform set of laws for everyone, but minorities often
                        oppose it. The common argument given is that these personal laws are related to religious
                        sentiments, and there is no need to interfere in them. Articles 29 and 30 are also used as an
                        argument by the opponents of UCC. </p>

                    <h3><em>What are Articles 29 and 30?: </em></h3>
                    <p>Article 29 protects the interests of minorities by making a provision that any citizen/section of
                        citizens having a distinct language, script, or culture has the right to conserve the same.
                        Article 29 mandates that no discrimination be done on religion, race, caste, language, or any of
                        them. </p>
                    <p>
                        Article 30 mandates that all minorities, whether based on religion or language, shall have the
                        right to establish and administer educational institutions of their choice.

                    </p>
                    <p> After understanding UCC, let's now discuss what has happened with respect to UCC in recent
                        times.
                    </p>
                    
                    <h2>UCC Private Member Bill</h2>

                    <p>A private member's Bill to implement the Uniform Civil Code in India was introduced in Rajya
                        Sabha on 9th December by BJP MP Kirodi Lal Meena, with 63 in favor and 23 votes against.
                        The Bill seeks to provide for the constitution of the "National Inspection and Investigation
                        Committee" to prepare the Uniform Civil Code and its implementation throughout the territory of
                        India.
                    </p>

                    <p>UCC was part of the BJP's platform for both the 1998 and 2019 elections and was even proposed for
                        introduction in the Parliament for the first time in November 2019 by Narayan Lal Panchariya.
                        The Bill was withdrawn at that time for making some amendments due to protests by opposition
                        MPs. For the second time, it was brought by Kirodi Lal Meena in March 2020 but was not
                        introduced again. A plea was also filed in the Delhi High Court for the establishment of a
                        judicial commission or a high-level expert committee to direct the central government to prepare
                        a draft of UCC in three months.</p>

                    <p>UCC has always been debated among political parties and religious groups. Even The Supreme Court
                        has focused on the UCC in a number of its rulings favoring a common law for all the citizens
                        reminding the spirit of Article 44. Still, it has also refrained from directing the government
                        to make laws in this regard.
                    </p>

                    <p>In the Shah Bano case in 1985, the court lamented: <em>"It is a matter of regret that Article 44
                            has remained a dead letter... Common civil code will help the cause of national integration
                            by removing desperate loyalties to laws, which have conflicting ideologies. No community is
                            likely to bell the cat by making gratuitous concessions on this issue. It is for the State,
                            which is charged with the duty of securing a uniform civil code and it has legislative
                            competence to do so."</em></p>
                    <p>Similarly, in other cases such as Sarla Mudgal Case (1995), the Ahmedabad Women Action Group case
                        (1997), the Lily Thomas case (2000), and John Vallamattom Case (2003), there has been a talk on
                        UCC.</p>
                    <p>However, Bharatiya Janata Party considers the introduction of the UCC as a core ideological
                        issue. It has, in the past, cited the example of Goa, which has a common law called the Goa
                        Civil Code.</p>
                    <h2>Issues with Implementation</h2>
                    <p>although Article 44 of the Indian Constitution directs the State to implement it, the UCC could
                        not be implemented in India due to various conflicts in personal laws because of diverse
                        cultural, social, and religious backgrounds and communities that follow different rules,
                        practices, and customs.
                    </p>
                    <p>In support of UCC, it is argued that it would make complicated laws related to marriage,
                        inheritance, succession, and adoptions easier to understand. It is also important for the
                        nation’s unity and dignity as it aims to protect marginalized groups, such as women, children,
                        and religious minorities.
                    </p>
                    <p>While those who challenge UCC argue that India is a secular republic nation, and freedom of
                        religion is also a fundamental right in India; besides that, Articles 29 and 30 guarantee
                        minorities to practice their own faith, culture, and customs. So, the legal debate mostly lies
                        along Articles 44, 29, and 30, where 44 supports the UCC while 29 and 30 oppose it.</p>
                    <p>It is evident that the people of India have hearty confidence in their respective religious
                        beliefs, practices, or customs that are always included as an essential part of their religion.
                        Still, primarily religious beliefs and religion-based personal laws are biased against women,
                        particularly Muslim personal law. Leaders always gave respect to such strong religious beliefs
                        and faiths because no Indian politician/ruler had ever tried to instigate feelings by changing
                        them.</p>
                    <p>In the past, no leaders have taken the courage in this regard to avoid affecting people’s strong
                        beliefs and personal feelings, as this not only affects their vote bank but also might cause an
                        intense backlash for them and their government from opposing communities and religious leaders.
                        Even during the Mughal era, many Mughal rulers obliterated Hindu temples and trampled Hindu
                        rights; however, it was rarely evident that they interfered with Hindu personal laws. Even the
                        British Government considered that the personal laws of Hindus and Muslims had to be left out of
                        such codification because Britishers were aware of the fact that it could instigate sentiments.
                    </p>
                    <p>However, UCC has been one of the most focused issues for the BJP. The demand for a UCC, which had
                        been articulated by the Sangh and later the Jan Sangh, the BJP’s political precursor, was made
                        part of the BJP’s election manifesto starting in 1998, and they seem to achieve it before 2024.
                        The Bharatiya Janata Party has fulfilled two of the three-long-standing items on its manifesto
                        and on the wish list of its ideological fount, the Rashtriya Swayamsevak Sangh -- the
                        construction of a Ram Mandir in Ayodhya and the revocation of Article 370 that conferred special
                        status on Jammu and Kashmir, UCC being the only left.</p>
                    <p>Thus, a strong political will is needed for the implementation of the Uniform Civil Code. A sense
                        of tolerance for other religions and mutual respect for each part of India is also essential.
                    </p>
                    <h2>Recommendations</h2>
                    <p>The following recommendations should be considered to implement the Uniform Civil Code in India.
                    </p>
                    <ul>
                        <li>
                            People should be urged to adopt a progressive and receptive mindset in order to comprehend
                            the rationale behind the introduction of UCC in India.</li>
                        <li>
                            Programs for sensitization, education, and knowledge should be set up to inform people about
                            UCC.</li>
                        <li>
                            The best interests of all religions should be considered when creating drafts of the uniform
                            civil code in order to achieve uniformity. For the purpose of making the draft on UCC, a
                            group of distinguished jurists should be formed.

                        </li>
                    </ul>
                    <h2>References </h2>
                    <ul>
                    <li>
                        <a href="http://www.legaladviceguru.com/the-uniform-civil-code">Advice Guru, By Legal. “UNIFORM CIVIL CODE.” Legal Advice Guru, 17 Apr. 2021</a>
                    </li>
                    <li>
                        <a href="http://www.allresearchjournal.com/archives/?year=2021&vol=7&issue=8&part=E&ArticleId=8877">Applied Research-info@allresearchjournal.com, International Journal of, and Dr. Gopala Anjinappa. “A Comprehensive Study on Pros and Cons in Implementation of Uniform Civil Code.” A Comprehensive Study on Pros and Cons in Implementation of Uniform Civil Code, 25 Aug. 2021</a>
                    </li>
                    <li>
                        <a href="http://www.legalservicesindia.com/article/2157/Uniform-Civil-Code-in-Goa.html">India, legal Service</a>
                    </li>
                    <li>
                        <a href="http://www.esamskriti.com/essay-chapters.aspx?sectionname=National-Affairs&subsectionname=Ideas-ad-Policy&topicname=Marriage-and-Divorce-Law-in-Goa-Civil-Code--&chapter=1">“Marriage and Divorce Law in Goa Civil Code.” Marriage and Divorce Law in Goa Civil Code</a>
                    </li>
                    <li>
                        <a href="http://www.staging.insightsonindia.com/courses/the-goa-civil-code">“The Goa Civil Code: - Insights Active Learning.” Insights Active Learning</a>
                    </li>
                    <li>
                        <a href="http://www.hindustantimes.com/india-news/has-the-supreme-court-set-the-ball-rolling-for-a-uniform-civil-code-101615802810671.html">“Has The Supreme Court Set the Ball Rolling for a Uniform Civil Code?” Hindustan Times, 15 Mar. 2021</a>
                    </li>
                    <li>
                        <a href="http://www.hindustantimes.com/india-news/bjp-rss-hope-for-consensus-on-ucc/story-ASFagZCCQPEIfQT59HMgkK.html">“BJP, RSS Hope for Consensus on Uniform Civil Code.” Hindustan Times, 5 Aug. 2020,</a>
                    </li>
                    <li>
                        <a href="http://www.economictimes.indiatimes.com/news/politics-and-nation/private-members-bill-on-ucc-introduced-in-rajya-sabha/articleshow/96111879.cms">“Private Member’s Bill on Uniform Civil Code Introduced in Rajya Sabha.” The Economic Times</a>
                    </li>
                    <li>
                        <a href="http://www.archives.palarch.nl/index.php/jae/article/view/8962">OJHA, MEGHA. “SCOPE FOR THE IMPLEMENTATION OF UNIFORM CIVIL CODE IN CONTEMPORARY INDIA: A SUGGESTION | PalArch’s Journal of Archaeology of Egypt / Egyptology.” SCOPE FOR THE IMPLEMENTATION OF UNIFORM CIVIL CODE IN CONTEMPORARY INDIA: A SUGGESTION | PalArch’s Journal of Archaeology of Egypt / Egyptology, 13 May 2021</a>
                    </li>
                </ul>
                </div>
            </div>
        </div>
    </article>
  )
}
