// components/Home.js
import React from 'react';

import './eventspage.css';
const Event = () => {
  return (
  <> 
       
        <div className="event" style={{backgroundImage: 'url("https://i.postimg.cc/jSdrmZx3/page.jpg")'}}>
        <div className="background-image1"><div className="flexbox2">
        <div className="containerbox1">
  <h1>Book Fair</h1><br></br>
  <p>
  Curiosity was ignited and horizons were expanded at the book fair hosted by public policy and opinion cell in our flagship event policy conclave 23. Attendees seized the opportunity to discover new authors and draw attention from the captivating pages of their stories. A diverse array of books awaited exploration, spanning fiction categories like anime books ,horror, crime ,fantasy as well as non fiction genres encompassing academics, biographies, history and motivational literature. The collective spirit of unity made it a memorable time for all participants. As we reflect on the event the shared experience underscores the vast world of knowledge that was uncovered and celebrated during this enriching literary gathering.
  </p>
  <img src="https://picsum.photos/400/250" alt="Description of your image" /><br></br>
  </div><center><div><a href="register.html" className="register-button">
    Register Now
  </a></div></center>
</div>
</div></div>

      </> 
  );
};

export default Event;
