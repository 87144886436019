import React from 'react'

export default function Recent_Development() {
  return (
    <article class="blog-single">
      {/* <!--Hero Section starts--> */}
      <div
        class="page-header page-header--single"
        style={{"background-image": "url(https://i.postimg.cc/rFrrJXZW/233112453-2574339919378520-5780656819065229425-n.jpg)"}}
      >
        <div class="row page-header__content">
          <div class="col-full">
            <h1 class="display-1">Recent Developments in Afghanistan Crisis</h1>
            <ul class="page-header__meta">
              <li class="author">
                By: <a href="#0">Arka Roy and Aditya Mishra</a>
              </li>
              <li class="date">Saturday, 7 August 2021</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--Hero Section Ends--> */}
      <div class="blog-content-wrap">
        <div class="row blog-content">
          <div class="col-full blog-content__main">
            <p class="lead drop-cap">
              If the top 5 most significant international unsolved disputes are
              listed, Afghanistan would surely be one of them. Three trillion
              dollars is the monetary cost that the US has endured in
              Afghanistan. Even more saddening is the number of lives lost.
              According to government reports, 2500 US soldiers have been
              killed, 4400 private US agents, 73 journalists, and 450 aid
              workers have been killed. However, the Afghans themselves endured
              the highest cost; research by Brown University, Rhode Island,
              suggests that 66000 Afghani soldiers have died along with 47425
              civilians.
            </p>
            <p style={{"text-align": "center"}}>
              <img
                src="https://static.dw.com/image/58172185_303.jpg"
                alt="hello"
              />
            </p>
            <h2>Background</h2>
            <p>
              The roots of such a vast international conflict go down 42 years
              deep in history, when the two superpowers of the post-World War
              era were entangled in the cold war. It began when Zahir Shah was
              removed from his throne by his cousin, Dawood Khan. Dawood Khan
              was a socialist ruler and liberal in giving women equal rights and
              civil rights for all, unlike his Islamic predecessor Zahir Shah.
              Now Russia, observing this as an opportunity to expand the reach
              of socialism, began helping Dawood Khan in getting strong rooted
              in power. This resulted in Dawood Khan founding the People's
              Democratic Party of Afghanistan. Russia even defended the PDPA
              government when Islamic leaders on 11th September 1979 cooped it.
            </p>
            <p>
              The US saw the Russian intervention in Afghanistan as a challenge
              and began supporting the staunch Islamic leaders locally. In this
              process, the USA support of Russian rivals ( like China,
              Pakistan). Pakistani intelligence agencies began to fund the
              training of local Islamic rebels(known as Mujahids) in
              Afghanistan. (Osama bin Laden was one such mujahid.) To organize
              the Mujahids, a school teacher named Mullah Umar started an
              organization in 1994 at Kandahar called Taliban (which means
              students or seekers). Supported by Pakistan and the US, the
              Taliban began expanding its region of control. With aggressive
              attacks, the Taliban succeeded in capturing the whole of
              Afghanistan on 24th September 1996
            </p>
            <h2>US Intervention</h2>
            <p>
              The US Taliban rivalry started when the US embassy was attacked in
              Tanzania and Kenya. US sought proper action against Al Qaeda,
              chaired by Osama bin Laden, but none was taken. So bin Laden was
              put on the US most wanted list. The local rivalry continued
              between the Taliban and the northern alliance. It was the largest
              terror attack against the US done on 11th September 2000 which
              shook the whole world and set the foundation of the bloody war to
              come. In the plane crashes of 9/11, about 3000 US citizens died.
              The United States responded swiftly and furiously. Huge amounts of
              money was pumped by the US in the war efforts against Taliban and
              Al Qaecda once they refused to surrender bin Laden, the mastermind
              of the attacks. The result of such a huge confrontation was the
              outright removal of Taliban’s control over Afghanistan in a short
              period of only a year. A very unfortunate event took place in
              early December when Taliban prisoners were being transferred in
              shipping containers, over 2500 died of suffocation and many were
              left dead when guards shot bullets on the containers to make air
              holes
            </p>
            <p style={{"text-align": "center"}}>
              <img
                src="https://lh6.googleusercontent.com/Y7rWP2qCH8rb33BWflsEa-_2UWKM_4WWz4uPg6fV8NczhJ-TuUSUpAUM4r1R468dp6RtMsjkc26TXjxUVyNbmjEU5B-IpAMInLI0ZnRxSGhGEGVZNQ01l63L6twZJaBPTP7-efYK=w294-h221"
                alt="hello"
              />
            </p>
            <p>
              The only thing left in US retribution was the capture of bin Laden
              himself. Huge funds were spent in gathering intelligence and
              monitoring any suspicious connections of laden. The efforts bore
              fruit in the year 2010 when an affiliate of him was captured and
              revealed that bin Laden was living in Abbottabad in Pakistan.
              After almost an year of confirmations and planning, Laden was
              killed in a very well planned strike. That almost completed the
              united states mission in Afghanistan. The NATO forces and us army
              stayed in Afghanistan to help set up the local government. Finally
              on 18th June, 2013 ISAF finally handed over the control of all the
              districts to the Afghan authorities and the security transfer was
              completed. On 28th December,2014 NATO officially ended the ISAF
              Combat mission. On 1st January, 2015 “Operation Resolute Support”
              was launched with the objective of providing training, advice and
              assistance to the Afghan Security Forces in their fight against
              Taliban and Other extremist groups.
            </p>
            <h2>Post-2017 Developments & Current Scenario</h2>
            <h3>2018 Peace Proposal</h3>
            <p>
              By the beginning of 2018 Taliban increased violence in
              Afghanistan, claiming more civilian lives. In this scenario,
              Afghan President Ashraf Ghani proposed a peace deal to the Taliban
              where the Afghan government will recognize the Taliban as a
              legitimate political party, remove sanctions against Taliban
              members, and provide other incentives. In return, the Taliban have
              to renounce violence, recognize the Kabul government and follow
              the rule of law. Twenty other countries also backed this proposal
              at the Tashkent Conference on 28th March.
            </p>
            <p>
              In the meantime, the Taliban also expressed their wish to open up
              talks with the United States and expressed that the Afghan issue
              must be solved through peaceful strategy instead of war. However,
              they did not accept Ghani's peace proposal publicly. However,
              during the Eid-al-Fitr in June, the Afghan Government and Taliban
              agreed to a temporary ceasefire.
            </p>
            <h3>US efforts to seal the peace</h3>
            <p>
              The US started its peace talk and withdrawal discussion with the
              Taliban in July 2018. Zalmay Khalilzad was appointed as special
              advisor on Afghanistan in the US State Department to lead the
              US-Taliban talks and facilitate the intra-Afghan peace process.
              However, the Taliban kept refusing to sit with the Afghan
              government for any dialogue, tagging it as a puppet government of
              the US. During 2019 US-Taliban talks made significant progress
            </p>
            <h3>US-Taliban Deal, 2020</h3>
            <p>
              After many rounds of dialogues, the US and Taliban signed a peace
              deal on 29th February 2020 in Doha, Qatar. It stated that the US
              and other foreign forces will be withdrawn from Afghanistan, and
              the US & her allies will not intervene in Afghanistan's domestic
              politics. On the other hand, the Taliban promised that they would
              not allow any anti-US activities in Afghan soil and start
              intra-Afghan peace talks. The UN Security Council endorsed the
              deal, but the official Afghan government was not a party to this
              deal
            </p>
            <p style={{"text-align": "center"}}>
              <img
                src="https://lh6.googleusercontent.com/xhc4iy3CO4oBkZIXf75v-IaEowHOZ-NYAyPzCSRAuhi4QC00Z0jqkkaBfc66u0LguCQsTgScHJhlcRVHVnMNJzYN7Kyzh5n229lf9CaWh8o1T2igglwXvmWF4RhI7Xqfcyye50_o=w563-h316"
                alt="hello"
              />
            </p>
            <h3>Intra- Afghan Talks</h3>
            <p>
              Although intra-Afghan talks scheduled to begin from March 2020 was
              being delayed for several reasons. Firstly, with the disputed
              Presidential Election in 2019, the negotiator team from the Afghan
              side was hard to decide. There was also a lack of consensus and
              trust regarding the prisoner exchange in the US- Taliban deal.
              Taliban also increased their level of violence at a horrible rate
              in 2020, creating a situation not suitable for any diplomatic
              discussion. In 45 days after the Doha agreement, the Taliban
              launched 4500 attacks in Afghanistan, killing more than 900 Afghan
              security personnel.
            </p>
            <p>
              However, on 17th May, a power-sharing deal was signed between
              Abdullah Abdullah and Ashraf Ghani ending the dispute over the
              2019 elections, and the Kabul administration released most of the
              prisoners as per Taliban demands. Finally, on 12th September 2020,
              intra-Afghan negotiations began in Doha, Qatar. .
            </p>
            <p style={{"text-align": "center"}}>
              <img
                src="https://lh3.googleusercontent.com/ED_orprJ33Pzdhr4tlpQ9kEUuaeNBRu5HQQ-CdRv7-bU6sZnrdNHK_oRMKb4dZYAeo1h3zRk_CqEBL2fRILTpESPR0xxz4_PouC4UrfzDb7uw1BqRSl-tBpYfRjgizy2n-ri-ONG"
                alt="hello"
              />
            </p>
            <h2>Final US – Withdrawal and Taliban Offensive</h2>
            <p>
              There was uncertainty among the international community whether
              newly elected US President Joe Biden will follow the terms of the
              US-Taliban deal, signed during the Trump administration. At last,
              Biden announced on 14th April 2021 that all US forces would be
              withdrawn before 11th September, the 20th anniversary of the 9/11
              terror attacks. However, to almost everyone's surprise, on 2nd
              July, US troops left the Bagram Airfield, its last base in
              Afghanistan, without even properly notifying the Afghan
              authorities and all other foreign troops also followed in their
              footsteps.
            </p>
            <p>
              Although after the 2020 agreement Taliban stopped attacking US
              forces, they increased the momentum of attack and violence on
              Afghan national forces. From May 2021, the Taliban launched a
              full-scale offensive against the Afghan government with US forces
              gone. They captured cities and districts one by one at a rapid
              pace. More than 200 of the 419 Afghan district centres are now in
              Taliban control. Almost 1000 civilians and 1400 security personnel
              have already been killed since 1st May, with numerous injured,
              deserted and surrendered to the Taliban. More than 1000 Afghan
              soldiers have fled to neighbouring countries. Many Afghan citizens
              have crowded the passport offices of Kabul to seek refuge in
              neighbouring countries, while some people are leaving the country
              without even proper paperwork. Foreign embassies and consulates,
              including Turkey, Russia, Uzbekistan, Tajikistan, India, and
              Pakistan, have closed or reduced their services in Afghanistan. A
              number of nations have suggested their citizens to leave the
              country. Around 50 Indian diplomats and citizens were flown back
              to India on 10th July from Kandahar on an emergency basis.
            </p>
            <p>
              Taliban is also making advances in Northern parts of the country,
              which was a traditional anti-Taliban stronghold. Government forces
              are being seen in a low morale position as they are leaving many
              towns and villages without even putting up a good fight. However,
              some describe this as a tactical retreat, efforts to form local
              militias to resist the Taliban show legitimate authority's
              vulnerability. Intelligence communities and analysts are
              re-assessing the situation of how long Kabul can resist the
              Taliban takeover.
            </p>
            <p style={{"text-align": "center;"}}>
              <img src="https://lh3.googleusercontent.com/-pCY-nMgtlbJXaGBft5d0Y7IXlR4wZ-8weCwYBpOI3Ney_VJxDfzbRsXYfKSa9EtqdpilBf_gSaWkjiZEpIetRozq9fRbJgDE23XQQb1u3Dt5bqdX0rioKFuzyftxGuIpoI7ujNF=w477-h488" alt=""/>
             </p>
             <h3>President Biden’s Remarks</h3>
             <p>On 8th July in the White House, President Biden made some remarks on the US exit and the current situation in Afghanistan. He said that the US did not go there to "nation-build", but their primary objective was to hunt down Osama Bin Laden, in which they have been successful. He stated that the other countries in the region also have their roles in this issue and the US will support them in their efforts. Biden also remarked that no nation has ever been successful in unifying Afghanistan, and it is very less likely that there will be one unified government controlling the whole territory. He said that Afghan leaders can do it, but whether they will show cohesion is a matter of question. Biden agreed that the Taliban is in its most robust military capacity since 2001, but he put his confidence in the Afghan military capability. He also assured that the US would continue to support Afghanistan in terms of equipment and findings</p>
             <p style={{"text-align": "center;"}}>
              <img src="https://lh6.googleusercontent.com/H9klFUZvuT3-aMgRW4ZYf-6QuvUx-CQNguXYuCwJHP_xZU7YjARSx6-HnFw4ccUNyJhpHGxfQaryID7GOpuhlvGp00-wji7TdVT4bPvYDo77kEMqJGVevBOvHZCCN8JUvqSRTgzT=w456-h263" alt=""/>
             </p>
             <h2>INDIA's Role and Challenges</h2>
             <h3>Historical Context</h3>
             <p>India and Afghanistan enjoyed a relationship of solid friendship and bonhomie over the decades on both the political and cultural spectrum. India was the only South Asian country that recognized the PDPA rule and the Soviet-backed Democratic Republic of Afghanistan. India also secretly helped the Northern Alliance in the late 90's in its fight against the Taliban. India also helped the US-led coalition forces by offering intelligence and other support to overthrow the Taliban regime. India was the main force behind the inclusion of Afghanistan into the SAARC (South Asian Association for Regional Cooperation). Indo-Afghan relationship was strengthened by the signing of the Strategic Partnership Agreement of 2011. India also has been training and providing material support to Afghan Security Forces for a long while</p>
             <p style={{"text-align": "center;"}}>
              <img src="https://lh4.googleusercontent.com/iDTIN-2xcwS6Nce0fBg5V1H2ZngjncPM7CrdQjfrqxVds5SzuNGDr-zjqNbJHA6l0p4F6htr0J98pRbDj5HkX-thv1E-hLPVFPkMl58GsJfogTud9eemwh-1PMmixA3oS74nTrQy" alt=""/>
             </p>
             <h3>Developmental Work & Humanitarian Aid</h3>
             <p>India has been increasing its soft power and influence over Afghanistan by participating in Nation-building in Afghanistan. With more than $3 billion in assistance, India has become the largest donor among the neighbours of Afghanistan. Some most notable works are the building of the Afghan Parliament, Salma Dam (Indo-Afghan Friendship Dam), Zaranj-Delaram Highway. India also helped to export Afghanistan through the Chabahar port of Iran. Besides these, India built numerous schools and hospitals for Afghan people; Provided scholarships to more than 16000 Afghan students and arranged vocational training for women</p>
             <p style={{"text-align": "center;"}}>
              <img src="https://lh5.googleusercontent.com/barriJdkjSTRobHueE9wL6BsIy5DUx3pq4ZDu8BLId4BJtDRiFgO3nX3Gu1eNVeKX1HlGsI7F_8CmeCJxnPd6Ys0jGU_IadykDm96DBXQPTRVss3T8oIzCjfPsH-S-IKjz2KK6nQ=w478-h260" alt=""/>
              <p style={{"text-align": "center;"}}>Indian PM Narendra Modi and Afghan President Ashraf Ghani on Inauguration of Parliament in Kabul</p>
             </p>
            <h3>Challenges for India</h3>
            <p>With the recent developments after the US exit from Afghanistan, India faces some severe challenges in that country.

              Of course, the first and foremost risk is that of terrorism. Indian embassies and consulates were attacked several times by militants. "Haqqani Group ", an integral faction of Taliban, has always been infamous for its anti-India activities. We know Afghanistan was used as a launchpad for international terrorism under the Taliban regime; the Kandahar Hijack crisis is still a nightmare for many Indians.
              
               Besides, the traditional Taliban, Islamic State – Khorasan(IS-K), has also expanded its roots in the region in recent times. The security vacuum created due to the US exit will only accelerate its growth. IS-K's ability to influence people towards radical ideologies is a far more dangerous concern for the peace and security of the region.</p>
               <p style={{"text-align": "center;"}}>
                <img src="https://lh6.googleusercontent.com/4LKPzbuJTf56Uinq84ggJ5TfrEaY-D1e-0V1uLjeepEV2ESAHUlyYtAU9byU8DEp6ctOFmBgokwToXJi6w0pDJIcdwVbucFy05lYtZkt51NtajuC4gri0DCxHNmBSndXh-OBKcRY=w523-h304" alt=""/>
               </p>
               <p>
                https://lh4.googleusercontent.com/lMcdgvA8HWQC1SeXImzIqeogl_Vy5zd9ZEqIwJPX6ouOGmVGSy47frdvp7iDR0gBLs31S4rMLXqXZ0sQBtul2RrrwRLJ_09pcdhLfZ9W0mSzmbw9fcHUVWtEcH43C7FkKYe-38ZZ=w482-h396
               </p>
               <p style={{"text-align": "center;"}}>
                <img src="https://lh4.googleusercontent.com/lMcdgvA8HWQC1SeXImzIqeogl_Vy5zd9ZEqIwJPX6ouOGmVGSy47frdvp7iDR0gBLs31S4rMLXqXZ0sQBtul2RrrwRLJ_09pcdhLfZ9W0mSzmbw9fcHUVWtEcH43C7FkKYe-38ZZ=w482-h396" alt=""/>
               </p>
               <p>Furthermore,  during the past years, India has invested a lot of resources in Afghanistan for long-term interests. India was successful in doing so because of the stability in the country created by US-presence. However, in recent years the internal political stability of Afghanistan is at risk not only due to the Taliban offensive but also due to a lack of consensus among the Afghan political classes and traditional tribes. Although Ashraf Ghani and Abdullah Abdullah signed the power-sharing agreement, its credibility is still in question. In this situation, any unstable government in Kabul will not be able to nurture and safeguard India's interest on Afghan soil</p>
            <h3>Current Stand of India</h3>
            <p>The Minister of External Affairs of India has recently pressed for a permanent solution to reduce violence in Afghanistan. He called for a permanent and comprehensive ceasefire and advocated the idea of "double peace", that is, peace both within and outside of Afghanistan. In an official stand, India will support all sorts of political solutions to bring peace to the Afghan people. However, India has consistently reiterated its zero-tolerance policy against terrorism and support for an "Afghan-led, Afghan-controlled and Afghan-owned Inclusive Peace Process". India also wants a more significant role of the United Nations in the Afghan peace process</p>
            <h3>Opportunities & Way Forward for India</h3>
            <p>There is no doubt that if anyone has gained from the 2020 US-Taliban deal, it is the Taliban and Pakistan. Moreover, whether India likes it or not, a large part of the International Community has recognized the Taliban as a dominant political force in Afghanistan. But there is always a silver lining behind the clouds. In recent times, the US has recognized India as a vital stakeholder in the Afghan peace process. With the political legitimacy Taliban wants to gain, greater responsibility of governing the nation will also be upon their shoulders, and they can not survive without external support. Although allegedly Pakistan and probably with the financial help of China-funded Taliban for terrorist activities, the increase in violence in Afghanistan will also create refugee problems in Pakistan and increase militant activities in the Uighur majority Xinjiang province of China. So, peace in Afghanistan is crucial for India and all its neighbours, including China, Pakistan, Russia, Iran, and others. To gain political command over Kabul, the Taliban must ensure that it will not let the Afghan soil be used in terrorist activities against the neighbours.
            </p><p>


              At least a part of the new Taliban leadership is more politically oriented and wants dialogue with India. Reportedly, India has also started backchannel conversations to ensure that they will not support any anti-India activity. India sent informal representatives in the 2018 Moscow talks and was also present in the Doha talks of September 2020. The relative neutral position of the Taliban regarding the Kashmir issue in recent times may also be viewed as a ray of hope for peace</p>
            <h2>Conclusion</h2>
            <p>
              The Afghanistan crisis is one of the longest-running armed
              conflicts in modern history. If counting from the 1978 -79 Soviet
              Invasion already 43 years have passed, claiming in numerous lives,
              producing uncountable refugees and fundamentally changing the
              paradigm of international politics multiple times. There are many
              parties involved with complex and conflicting interests. The
              gamble of violence has crippled the nation to its knees. So, peace
              is the only solution for the security, stability and development
              of the region. The Afghan peace process is a rare opportunity for
              the international community to prove its capacity to build a
              nation from the ashes of destruction; stand in solidarity during
              the hard times, and create an example before upcoming generations
            </p>
          </div>
        </div>
      </div>
    </article>
  )
}
