import React from 'react'

export default function Global_food_crisis() {
  return (
    <article class="blog-single">
        {/* <!--Hero Section starts--> */}
        <div class="page-header page-header--single"
            style={{"background-image": "url(https://i.postimg.cc/NfWdKWRj/GLOBAL-FOOD-CRISIS.png)"}}>
            <div class="row page-header__content">
                <div class="col-full">
                    <h1 class="display-1">Global Food Crisis</h1>
                    <ul class="page-header__meta">
                        <li class="author">By: <a href="#0">Ritesh Kantule, Arnesh Dadhich, Manuja Pandey, Shreya
                                Singh</a></li>
                    </ul>
                </div>
            </div>
        </div>
        {/* <!--Hero Section Ends--> */}
        <div class="blog-content-wrap">
            <div class="row blog-content">
                <div class="col-full blog-content__main">
                <blockquote>
                    <p>
                        Disclaimer: The views and the opinion expressed in this article are those of the author and
                        does not necessarily reflect the views and positions of Public Policy and Opinion Cell IITK.
                    </p>
                </blockquote>
                <br/>
                    <h2>Food Security</h2>
                    <p>
                        Food safety, as described by the United Nations Committee on global meals security, is the way
                        that every person always has the physical, social, and monetary right of entry to sufficient,
                        secure, and nutritious meals that meet their food choices and dietary needs for an energetic and
                        healthful life. (“Food Security - Agriculture” 2019)
                    </p>
                    <h2>
                        Changing Concepts About Food Security
                    </h2>
                    <ul>
                        <li>
                            In the 1950s and 1960s, food security was defined as sufficient protein and energy (amount
                            of food) consumed.
                        </li>
                        <li>
                            In the 1980s, the importance of micronutrients for a balanced and nutritious diet (food
                            quality) was recognized.
                        </li>
                        <li>
                            Even with adequate intake in the 1990s, nutrient absorption problems (e.g., diarrhea) or
                            excessive nutrient loss (e.g., respiratory infections) can impair food utilization.
                        </li>
                        <li>
                            Food security is an essential determinant of nutrition and health.
                        </li>
                    </ul>
                    <h2>World Food Summit 1996: Redefining food security</h2>
                    <p>Meal protection is the physical and monetary get admission of anyone to ok, secure, and
                        nutritious food at all times to satisfy their nutritional desires and food choices for an
                        energetic and healthful lifestyle. Exists while on hand.</p>
                    <h2>Dimensions of Food Insecurity</h2>
                    <ol>
                        <li>
                            <h3>Acute</h3>
                            <p>It is usually associated with natural or man-made disasters (e.g., War in Ukraine).
                                Severe and prolonged acute food insecurity is life-threatening and requires vigorous and
                                effective interventions.</p>
                        </li>
                        <li>
                            <h3>Chronic</h3>
                            <ul><b>Transient</b> i.e., short-term and temporary (e.g., during drought), or <br />
                                <b>Long-term and persistent</b> (e.g., poverty in marginalized population groups)
                            </ul>
                        </li>
                    </ol>
                    <h2>What Causes Food Insecurity? </h2>
                    <ol>
                        <li style={{"font-weight": "700;"}}>Short-term cause of Food crisis</li>
                        <p>The reasons for hunger and food insecurity vary from country to country, but generally, it is
                            a result of conflict, poverty, economic shocks such as hyperinflation and rising commodity
                            prices, and environmental shocks such as flooding or drought. (“Global food crisis”, n.d.)
                        </p>
                        <ul>
                            <li style={{"font-weight": "700;"}}>War in Ukraine</li>
                            <p>
                                The conflict in Ukraine sent global food prices skyrocketing. A third of the world's
                                wheat supply comes from Ukraine or Russia. Ukraine supplies the world with sunflower
                                oil, barley, corn, and fertilizers. But the ongoing conflict means that fields will not
                                be ready, crops will not be planted, and fertilizers will not be available.
                            </p>
                            <ol>
                                <li style={{"font-weight": "700;"}}>Wheat</li>
                                <p>Rapidly after Russia's attack on Ukraine in overdue February, charges for extensively
                                    traded wheat commenced at around $7.70 a bushel and fell to $13, in keeping with
                                    futures contracts traded in Chicago, a worldwide wheat hub. The price hovered in
                                    nearly double digits until it began to fall in mid-June. Wheat changed into buying
                                    and selling at just over $8 a bushel on August 1st. After the preliminary surprise
                                    of the invasion, rising costs discouraged a few nations from buying wheat, lowering
                                    demand and expenses. extended supply from the iciness wheat harvest has additionally
                                    pushed charges lower in the latest weeks (Rennison 2022)(New York Times)</p>
                                <p style={{"text-align": "center"}}>
                                    <img src="https://i.postimg.cc/JnXBs28j/image.png"
                                        alt=""/>
                                </p>
                                <li style={{"font-weight": "700;"}}>Sunflower Oil</li>
                                <p>Ukraine and Russia account for about 60% of the world's production of sunflower oil,
                                    and conflict has hit supply hard. Analysts at Mintech said sunflower oil prices rose
                                    from $1,130 a ton in February to more than $1,805 a ton in March, while cooking oil
                                    prices hit a record high. Soared 60% after the invasion of Ukraine. This has
                                    affected the prices of palm oil, canola oil and soybean oil. During the crisis,
                                    Ukraine could not export her 10 million tons of sunflower seeds (4 million tons of
                                    sunflower oil). (Wood, Zoe. 2022)</p>
                                <p style={{"text-align": "center"}}>
                                    <img src="https://i.postimg.cc/8cqKh3fD/image.png"
                                        alt=""/>
                                </p>
                                <p style={{"text-align": "center"}}>
                                    <img src="https://i.postimg.cc/s2LGTRmk/unnamed.png" alt=""/>
                                </p>
                            </ol>
                            <li style={{"font-weight": "700;"}}>A deal to free trapped grain provides only partial relief.</li>
                            <p>The main factor pushing down wheat prices was progress in negotiations over the fate of
                                his more than 20 million tonnes of grain stuck in Ukraine's Black Sea ports. An
                                agreement was reached to open export corridors so that some of the grain trapped by the
                                war could be shipped around the world. For the first time in more than five months, a
                                ship loaded with grain left a port in the Odessa region of Ukraine on her August 1st.
                            </p>

                        </ul>
                        <li style={{"font-weight": "700;"}}>Long Term</li>
                        <p>Regardless of good sized development made over the last few decades in lowering hunger,
                            almost 800 million humans are chronically undernourished as of 2015. An anticipated 161
                            million youngsters below the age of 5 are stunted. at the equal time, 500 million people are
                            obese. two billion people lack the essential micronutrients they need to live a wholesome
                            lifestyles. The FAO estimates that food manufacturing will want to boom via 60 percentage by
                            means of 2050 to meet increasing demand driven with the aid of populace growth and dietary
                            changes.</p>
                        <p>It is not enough to have enough food produced globally to meet demand – there is now enough
                            food produced globally, but there are still nearly 800 million hungry people – but that
                            everyone has access to it in the right quantity and quality at all times. According to the
                            United Nations, in 2015, 836 million people in the world still lived in extreme poverty
                            (less than US$1.25/day). And according to the International Fund for Agricultural
                            Development (IFAD), at least 70 percent of the very poor live in rural areas, most of them
                            partially (or entirely) dependent on agriculture1 for their livelihoods. An estimated 500
                            million small farms in the developing world support nearly 2 billion people, and in Asia and
                            sub-Saharan In Africa, these small farms produce about 80 percent of the food consumed.
                            (“Climate change and food security: risks and responses”, n.d.)</p>
                        <ul>
                            <li style={{"font-weight": "700;"}}>Climate change</li>
                            <p style={{"text-align": "center"}}>
                                <img src="https://i.postimg.cc/sX6zYVJW/unnamed-1.png" alt=""/>
                            </p>
                            <p>Schematic representation of the cascading effects of climate change impacts on food
                                security and nutrition. A range of physical, biological, and biophysical impacts bear on
                                ecosystems and agroecosystems, translating into impacts on agricultural production. This
                                has quantity, quality, and price effects, with impacts on the income of farm households
                                and on the purchasing power of non-farm households. All four dimensions of food security
                                and nutrition are impacted by these effects.
                            </p>
                            <li style={{"font-weight": "700;"}}>Impacts of climate change on freshwater availability </li>
                            <p>An increase in temperature will trigger increased demand for water for evapotranspiration
                                by crops and natural vegetation and will lead to more rapid depletion of soil moisture.
                            </p>
                            <p style={{"text-align": "center"}}>
                                <img src="https://i.postimg.cc/sD4HBnDF/image.jpg"
                                    alt=""/>
                            </p>
                            <p>Climate change is adding significant uncertainty to the availability of water in many
                                regions in the future. It will affect precipitation, runoff, and snow/ice melt with
                                effects on hydrological systems and water quality, temperature, and groundwater
                                recharge. Climate change will also significantly impact sea level with potential impacts
                                on the salinity of surface and groundwater in coastal areas. (“Causes, Effects and
                                Solutions to Food Insecurity (Lack of Access to Food)”, n.d.) </p>
                            <li style={{"font-weight": "700;"}}>Rapid population growth</li>
                            <p>We live in a world where, despite the death rate, the birth rate is higher. A growing
                                population means there are more mouths to feed daily. Population growth with limited
                                growth in available food means an increase in food insecurity..</p>
                            <li style={{"font-weight": "700;"}}>Wastage of Food</li>
                            <p> Every year, mostly in developed countries, edible food worth billions is thrown away.
                                For example, during this coronavirus pandemic, farmers are throwing away billions worth
                                of food because restaurants and supermarkets that used to buy such food are closed.</p>
                            <p>
                                The level of production is higher than consumption, which means that food must be thrown
                                away, yet people in developing countries are dying of hunger. The FAO reports that about
                                1.3 billion tonnes of food is wasted worldwide each year.
                            </p>
                        </ul>
                        <li style={{"font-weight": "700;"}}>Some Extra-<br />Groundwater</li>
                        <p>Irregularity in rising and falling groundwater levels across the globe</p>
                        <p style={{"text-align": "center"}}>
                            <img src="https://i.postimg.cc/wMV5BG50/image.jpg"
                                alt=""/>
                        </p>
                    </ol>

                    <h2>Current Situation</h2>
                    <p>World hunger remains alarmingly high. Nearly 193 million people in 53 countries and territories
                        will be severely food insecure and in urgent need of assistance by 2021, according to the
                        findings of the GRFC 2022 Dies Global Report on the Food Crisis. (GRFC) broke all previous
                        records. That's an increase of about 40 million compared to the 2020 peak (reported in GRFC
                        2021).</p>
                    <p style={{"text-align": "center"}}>
                        <img src="https://i.postimg.cc/9MB140Cd/image.png"
                            alt=""/>
                        <img src="https://i.postimg.cc/R0SW-SZp5/njk.png"
                            alt=""/>
                    <p>(IPC- Integrated Food Insecurity Phase Classification)</p>
                    </p>
                    <p>Dramatic increases are largely due to ongoing or worsening conflicts, existing and
                        COVID-19-related economic shocks, extreme weather events, or a combination of these factors. It
                        is also due to increased population coverage in countries such as Democratic Republic of the
                        Congo and the use of corrected population figures such as in Afghanistan.</p>
                    <p>Significant increases were reported in eight countries, accounting for 35 million additional
                        people in Crisis are worse (IPC/CH Phase 3 or above): Afghanistan, with around 10 million
                        additional people in these phases; Ethiopia (8 million); the Democratic Republic of the Congo (5
                        million); Nigeria (4 million); Pakistan and Yemen (3 million each) and Angola and Somalia (1
                        million each).</p>
                    <p>Significant increases were reported in eight countries, accounting for 35 million additional
                        people in Crisis are worse (IPC/CH Phase 3 or above): Afghanistan, with around 10 million
                        additional people in these phases; Ethiopia (8 million); the Democratic Republic of the Congo (5
                        million); Nigeria (4 million); Pakistan and Yemen (3 million each) and Angola and Somalia (1
                        million each).</p>
                    <ul>
                        <li style={{"font-weight": "700;"}}>Famine Likely (IPC Phase 5) in localized areas of South Sudan </li>
                        <p>During the first half of 2021 in South Sudan, the western payams of Pibor county (Gumuruk,
                            Pibor, Lekuangole and Verteth) continue to face Famine Likely (IPC Phase 5), according to
                            the IPC Famine Review Committee (FRC) (IPC and External Reviews, December 2020)</p>
                        <li style={{"font-weight": "700;"}}>Populations in Catastrophe (IPC Phase 5) in four countries</li>
                        <p>Over 570 000 people in localized areas of four countries were in Catastrophe (IPC Phase 5) in
                            2021 and required urgent action</p>
                        <p style={{"text-align": "center"}}>
                            <img src="https://i.postimg.cc/qvjKSF4G/image.png"
                                alt=""/>
                        </p>
                        <li style={{"font-weight": "700;"}}>'Risk of Famine' in three countries</li>
                        <p>In South Sudan, during the first half of 2021, the IPC FRC issued a Risk of Famine statement
                            for Kizongora and Maruwa payams. In Ethiopia's Tigray region, the IPC classified key areas
                            in Emergency (IPC Phase 4) as the most likely scenario.</p>
                        <li style={{"font-weight": "700;"}}>Populations in Emergency (IPC/CH Phase 4) </li>
                        <p>Around 39 million people were in Emergency (IPC/CH Phase 4) in 36 countries/territories in
                            2021. Afghanistan, Democratic Republic of the Congo, Yemen, Ethiopia, the Sudan, and South
                            Sudan, had more than 2 million people each in Emergency (IPC/ CH Phase 4), while Haiti and
                            Pakistan had around one million people each. These countries accounted for 82 percent of the
                            total global number in this phase. (GRFC 2022)</p>
                        <p style={{"text-align": "center"}}>
                            <img src="https://i.postimg.cc/8PCNwXXG/image.png"
                                alt=""/>
                        </p>
                        <li style={{"font-weight": "700;"}}>Populations in Stressed (IPC/CH Phase 2).</li>
                        <p>236 million people were in Stressed (IPC/CH Phase 2) in 41 countries with IPC/CH analyses in
                            2021, requiring action for disaster risk reduction and protecting livelihoods.(GRFC 2022)
                        </p>
                        <p style={{"text-align": "center"}}>
                            <img src="https://i.postimg.cc/Qdp47hgd/image.png"
                                alt=""/>
                        </p>
                        <li style={{"font-weight": "700;"}}>Global Hunger Map- 2020</li>
                        <p style={{"text-align": "center"}}>
                            <img src="https://i.postimg.cc/Y9VZ38vr/image.png"
                                alt=""/>
                        </p>
                    </ul>

                    <h2>Solutions to Global Food Crisis-</h2>
                    <p>For example, the United Nations has set 17 Sustainable Development Goals to be achieved by 2030.
                        The second goal is to achieve food security and better nutrition, end hunger and promote
                        sustainable agriculture.<br />
                        So it is up to every individual, family, group, organization, corporation, institution and
                        government to achieve food security by 2030. Most institutions and countries have not done much,
                        but there are hopes for food security. (“Causes, Effects and Solutions to Food Insecurity (Lack
                        of Access to Food)”, n.d.)
                    </p>

                    <ol>
                        <li style={{"font-weight": "700;"}}>Reduce Food Waste</li>
                        <p>Food is wasted primarily through inefficient preparation, uneven or poor roads, overly
                            selective customers, and inadequate storage facilities. When storage facilities are
                            improved, and food is properly prepared, less food is wasted, resulting in safer
                            communities. As mentioned above, FAO reports that about 1.3 billion tonnes of food are
                            wasted worldwide yearly.</p>
                        <li style={{"font-weight": "700;"}}> Reduce the Risk of Commercialising
                        </li>
                        <p>Levels of food insecurity are reduced when food is grown to feed communities and nations.
                            Farmers can produce more food and cash crops if there are enough food crops on the market.
                            If enough food is on the market, the risk of commercializing food is low. Governments should
                            also implement programs to let farmers know there is enough food for everyone.
                        </p>
                        <li style={{"font-weight": "700;"}}>Improve Trade Policies</li>
                        <p>Some farmers cannot feed their communities due to unfair trade policies. Giant companies have
                            already entered and commercialized food, making it difficult for small farmers to bring
                            their produce to market. Governments, therefore, need to improve these policies and ensure
                            that participation is fair for all.</p>
                        <li style={{"font-weight": "700;"}}>Close the Yield Gap</li>
                        <p>Most of our farmland is depleted of its natural level of fertility and cannot produce as much
                            as it did years ago. To fill the gap, governments, therefore,sible agricultural institutions
                            have developed strategies and programs to improve crop yields, especially local methods of
                            sustainable agricultural intensification, such as land management and soil
                            improvement.<br />
                            Land conversion should be avoided as it encroaches on natural habitats and forests, affects
                            biodiversity and wildlife, and increases greenhouse gas emissions. The strategy or set of
                            the program should ensure that food is available to all and that wildlife and forests are
                            not affected.
                        </p>
                    </ol>
                    <h2>Need to Prioritize Smallholder Agriculture: </h2>
                    <p>The report demonstrated the need for a greater focus on small-scale agriculture as a first
                        humanitarian response, to overcome access constraints and as a solution to reverse negative
                        long-term trends.</p>
                    <h2>Steps Taken By India</h2>
                    <ul>
                        <li>PM Garib Kalyan Ann Yojana (PMGKAY) </li>
                        <li>One Nation One Ration Card</li>
                        <li>Atmanirbhar Bharat Rozgar Yojana </li>
                        <li>Pradhan Mantri Kisan Samman Nidhi </li>
                        <li>Intensified Mission Indradhanush 3.0 Scheme
                        </li>
                    </ul>
                    <h2>Holistically the problem is not with production, but with the purchasing power of countries.
                    </h2>
                    <h3>Indian Perspective</h3>
                    <p>Rice could emerge as the next challenge for global food supply as the shortage of rain in parts
                        of india, which is by far the world’s biggest exporter of rice has caused planting area to
                        shrink to the smallest in the past three years.<br />
                        This is the peak time when many countries are suffering from rampant inflation. meanwhile here
                        in India total rice planted area has declined by 13% so far this season due to a lack of
                        rainfall in many areas like west Bengal and Uttar Pradesh. The Indian government has already
                        curbed wheat and sugar exports to safeguard food security and control local prices within the
                        nation..
                    </p>
                    <p style={{"text-align": "center"}}>
                        <img src="https://i.postimg.cc/h4Tp6Kwb/image.jpg"
                            alt=""/>
                    </p>
                    <p>severe heatwave, the Russia-Ukraine war, and loopholes in supply-demand control are some of the
                        factors that have led to the wheat crisis in India, particularly and the sector in well-known.
                        (Jaipurkar 2022)</p>
                    <ul>
                        <li style={{"font-weight": "700;"}}>Wheat</li>
                        <p>India, though the second largest wheat producer within the globe after China, is not even
                            taken into consideration as a first-rate wheat exporter. So, when India's unexpected
                            selection to ban wheat exports shook the sector, analysts considered this as an unheard of
                            meals disaster unfolding against the backdrop of severe wheat shortage.</p>
                        <p>As wheat production dips, the middle could reduce procurement and encourage open market sales
                            to reduce expenses, accordingly supporting preserve meal inflation on top of things.
                        </p>
                        <p>By giving a free hand to the private sector to export and not releasing buffer stocks from
                            the Food Corporation of India (FCI) godowns, the government can help Indian farmers realize
                            higher prices and the world meet its wheat deficit.</p>
                        <p>The government should also promote coarse grains like jowar, bajra, kodo, raagi, and other
                            millets in mid-day meals or government welfare programs because of their high nutritional
                            value.</p>
                    </ul>
                    <h2>References</h2>
                    <ul>
                        <li><a href="https://www.conserve-energy-future.com/causes-effects-solutions-food-insecurity.php">“Causes, Effects and Solutions to Food Insecurity (Lack of Access to Food).” n.d. Conserve Energy Future. Accessed December 12, 2022. </a></li>
                        <li><a href="https://21stcenturywire.com/wp-content/uploads/2022/07/FAO-Climate-change-and-Food-security-.pdf">“Climate change and food security: risks and responses.” n.d. Food and Agriculture Organization of the United Nations. Accessed December 12, 2022. </a></li>
                        <li><a href="https://www.drishtiias.com/to-the-points/paper3/food-security-1">“Food Security - Agriculture.” 2019. Drishti IAS. </a></li>
                        <li><a href="https://plan-international.org/emergencies/global-food-crisis/">“Global food crisis.” n.d. Plan International. Accessed December 12, 2022. </a></li>
                        <li><a href="https://thefederal.com/explainers-2/indias-wheat-export-ban-and-its-effects">Jaipurkar, Anoop. 2022. “Three reasons behind India's wheat shortage, and ways to avert future crises.” The Federal News. </a></li>
                        <li><a href="https://www.nytimes.com/2022/08/01/business/wheat-ukraine-war-market.html">Rennison, Joe. 2022. “What Drives the Price of Wheat, Beyond War in Ukraine.” The New York Times. </a></li>
                        <li><a href="https://www.fao.org/documents/card/en/c/cb9997en"></a>“2022 Global Report on Food Crises.” n.d. Food and Agriculture Organization of the United Nations. Accessed December 12, 2022. </li>
                        <li><a href="https://www.theguardian.com/business/2022/apr/05/food-price-rises-ukraine-war-cooking-oil.">Wood, Zoe. 2022. “Sunflower shortage: why cooking oil has become so expensive.” The Guardian. </a></li>

                    </ul>
                </div>
            </div>
        </div>
    </article>
  )
}
