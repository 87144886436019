import React from 'react'

export default function World_employment() {
  return (
    <article class="blog-single">
      {/* <!--Hero Section starts--> */}
      <div
        class="page-header page-header--single"
        style={{"background-image": "url(https://i.postimg.cc/L5fS5qpL/198562653-931093147743586-5462154061409398726-n.jpg)"}}
      >
        <div class="row page-header__content">
          <div class="col-full">
            <h1 class="display-1">World Employment and Social Outlook</h1>
            <ul class="page-header__meta">
              <li class="author">By: <a href="#0">Abhishek Bansod</a></li>
              <li class="date">Thursday, 10 June 2021</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--Hero Section Ends--> */}
      <div class="blog-content-wrap">
        <div class="row blog-content">
          <div class="col-full blog-content__main">
            <p class="lead drop-cap">
              The International Labour Organisation recently released its
              flagship report on “World Employment and Social Outlook: Trends
              2021”. The report comprehends the Global Pandemic's negative
              impacts, highlighting how the crisis is unevenly distributed
              amongst various regions and social structures.
            </p>
            <blockquote>
              <p>
                “In 2020, an estimated 8.8% of total working hours were lost –
                the equivalent of the hours worked in one year by 255 million
                full‐time workers. The shortfall corresponding to the working
                hours in the first half of 2021 is equivalent to 140 million
                full‐time jobs in the first quarter and 127 million full‐time
                jobs in the second quarter – highlights that the crisis is far
                from over.“
              </p>
            </blockquote>
            <p style={{"text-align": "center;"}}>
              <img
                src="https://lh6.googleusercontent.com/SrOVaIEdVPclcwN7f7V_E_fFss9-paJmyzq3QcryJjdKeFVVp3IK-yg85H3k9JPuBLh41QW8q6u8NNlpJU6mmfbpRJIS8yO_B-A8JFGWFJAIKAdpNFGJPIjct99lJddp32OpYSiW"
                alt="hello"
              />
            </p>
            <p style={{"text-align": "center;"}}>
              <img
                src="https://1.bp.blogspot.com/-Fmg60wRmWB4/YMIkovKy9lI/AAAAAAAAAHg/k8bImR2SQRoY3qeec_6QFT7P5T_Kh4vGwCLcBGAsYHQ/w567-h355/Screenshot%2B2021-06-10%2Bat%2B7.43.38%2BPM.png"
                alt="hello"
              />
            </p>
            <h2>Poverty Rates have reverted to those of 2015</h2>
            <p>
              The total working‐hour losses have translated into a sharp drop in
              labour income and an increase in poverty. Global labour income,
              which does not include government transfers and benefits, was
              US$3.7 trillion (8.3% lower in 2020 than it would have been in the
              absence of the pandemic). Relative to 2019, an estimated
              additional 108 million workers are now extremely or moderately
              poor, meaning that they and their family members are having to
              live on less than US$3.20 per day in purchasing power parity
              terms. Five years of progress towards eradicating working poverty
              have been undone, as operating poverty rates have reverted to
              2015.
            </p>
            <h2>Recovery is possible- but can it set things right?</h2>
            <p>
              The recovery will be relatively faster in high‐income countries.
              In low‐ and middle‐income countries, the more limited access to
              vaccines and greater constraints on fiscal spending will dampen
              the employment recovery.” A globally uneven economic recovery
              process can be expected to begin from the second half of 2021
              onwards, driven by progress in vaccination and large-scale fiscal
              spending. But the projected employment growth will be too weak to
              provide sufficient employment opportunities for those who became
              inactive or unemployed during the pandemic and for younger cohorts
              entering the labour market, who have suffered significant
              disruptions to their education and training. Globally, the
              recovery is projected to result in the net creation of 100 million
              jobs in 2021 and an additional 80 million jobs in 2022. Projected
              employment in 2021, however, will still fall short of its
              pre‐crisis level. In addition, there will likely be fewer jobs
              than would have been created in the absence of the pandemic.
              Taking this forgone employment growth into account, the
              crisis‐induced global shortfall in jobs is projected to stand at
              75 million in 2021 and 23 million in 2022
            </p>
            <p style={{"text-align": "center;"}}>
              <img
                src="https://lh3.googleusercontent.com/F_0Fy9q2KUKVCEMRIkNkzGub1aKFU_k_GRrkMXvpbVBxF_Wbyg56lzJVL7B36sr-weMbL2dvYj0_wzXKuHp6aAzXg2ZVAof5AgcIgyzyci0Ticvaqlyqwwu3lOap35FAGtKymRlV"
                alt="hello"
              />
            </p>
            <h2>
              Are Unemployment and Poverty the only issues of the labour force
            </h2>
            <p>
              Apart from the Financial crisis, a plethora of already existing
              work deficits and social issues have come up in the light of this
              ordeal.
            </p>
            <p style={{"text-align": "center;"}}>
                <img src="https://1.bp.blogspot.com/-A8nrTwhyEfc/YMImZcczBFI/AAAAAAAAAHw/raaIMsPms0U50BY1WRXTEXgQBNAYaRXYQCLcBGAsYHQ/w607-h379/imgonline-com-ua-resize-oDcs0YjHs2BGjW7.jpg" alt="hello" />
              </p>
              <ol>
                <li>
                    Many newly created jobs are expected to be of low productivity and poor quality. As a result of low growth in gross domestic product and a substantial increase in the working‐age population, the lack of productive employment opportunities will be most severe in low‐income countries. In these countries, average annual labour productivity growth is projected to decline from an already meagre 0.9% for 2016–19 to a negative rate of –1.1% for 2019–22. 
                </li>
                <li>
                    Many businesses, particularly micro and small enterprises, have already gone bankrupt or are facing a highly uncertain future, with negative consequences for their future productivity and their ability to retain workers. According to an ILO survey of 4,520 businesses in 45 countries worldwide undertaken in the second quarter of 2020, 78% of micro‐enterprises and 71% of small firms were facing significant financial difficulties.
                </li>
                <li>
                    Informal workers have also been affected disproportionately by the crisis. Roughly 2 billion workers – or 60.1 per cent of the globally employed – were working informally in 2019.  Informal employees were three times more likely than their formal counterparts and 1.6 times more likely than the formal self‐employed to lose their jobs due to the crisis, thereby contributing to the observed shift towards self‐employment. The variation of this change is drastically more amongst informal employees than formal employees thereby highlighting their acute vulnerability.
                </li>
                <li>The ability to work from home in higher‐skilled occupations and areas with readier access to the internet has accentuated inequalities between the global North and the global South, between households with different socio-economic status, and between rural and urban areas. Also, the shift to working from home can potentially weaken social cohesion, as workplaces have traditionally played an essential role as venues for human interaction. 
                </li>
              </ol>
            <section id="blog">
              <div class="blog-box">
                <div class="blog-image">
                  <img
                    src="https://1.bp.blogspot.com/-52y-2y7kFzI/YMIgJeqxyhI/AAAAAAAAAG4/pf6e6jB5m0wwczqUrxtB7-m1d6sEGh6-QCLcBGAsYHQ/w298-h320/Screenshot%2B%2528140%2529.png"
                    alt="Blog"
                  />
                </div>
                <div class="blog-image">
                    <img
                      src="https://1.bp.blogspot.com/-U_6RpjLYP6M/YMIfy4ZfZDI/AAAAAAAAAGw/c8jLxjcDDeg7aAPfhNnW9tmFYWsD_1BMQCLcBGAsYHQ/w285-h301/Screenshot%2B%2528141%2529.png"
                      alt="Blog"
                    />
                  </div>
              </div>
            </section>
            <section id="blog">
              <div class="blog-box">
                <div class="blog-image">
                  <img
                    src="https://1.bp.blogspot.com/-dvyJCf-Wlho/YMIibT6oeOI/AAAAAAAAAHQ/afY7ZyFeA3Y5xX9ldktBuFfv0B8aTaV-wCLcBGAsYHQ/s320/imgonline-com-ua-resize-5eQNndXMBzcjVW2.jpg"
                    alt="Blog"
                  />
                </div>
                <div class="blog-details">
                  <h4>Good as Gold Blog</h4>
                  <p>
                    The crisis threatens to jeopardise progress on gender equality, as women have suffered disproportionate job losses while seeing their unpaid working time increase. The disruption to labour markets has had devastating consequences for both men and women, yet women’s employment declined by 5% in 2020 compared with 3.9% for men.
                  </p>
                </div>
              </div>
            </section>
            <h3>Lack of hygiene and health facilities</h3>
            <p>
                The crisis has affected many young people at a critical moment in their lives, disrupting their transition from school or university to work. Evidence from previous problems shows that entering the labour market during a recession reduces longer‐term employment probabilities, wages and the prospects for on‐the‐job skills development.
            </p>
            <p>
                The COVID-19 crisis has further highlighted the vulnerable situation of migrant workers. Many migrant workers experienced an abrupt termination of their employment along with non‐payment or delayed payment of wages, and at the same time often lacked access to social protection benefits that could make up for their income losses. 
            </p>
            <h2>The Silver Lining….</h2>
            <p>
                The report suggests the need for an International “human-centred” policy to recover and prevent further losses from the Pandemic. As stated in the ILO Centenary Declaration for the Future of Work (2019), such an endeavour involves “put[ting] workers’ rights and the needs, aspirations and rights of all people at the heart of economic, social and environmental policies”.  Some of the suggestions from the report include promotion of broad‐based economic growth and the creation of productive employment through investment in sectors, supporting household incomes and labour market transitions, particularly for those most affected by the crisis, through active labour market policies, public employment services and publicly provided, high‐quality care services. 
                It also stresses strengthening the institutional foundations of inclusive, sustainable and resilient economic growth and development by enhancing social protection systems, promoting formalisation, and ensuring that all workers, irrespective of their contractual arrangements, have the right to freedom of association and collective bargaining, enjoy safe and healthy working conditions and receive adequate minimum wages.                
            </p>
          </div>
        </div>
      </div>
    </article>
  )
}
