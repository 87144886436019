// components/Home.js
import React from 'react';

import './eventspage.css';
const Event = () => {
  return (
  <> 
       
        <div className="event" style={{backgroundImage: 'url("https://i.postimg.cc/jSdrmZx3/page.jpg")'}}>
        <div className="background-image1"><div className="flexbox3">
        <div className="containerbox1">
  <h1>Videsh Neeti</h1><br></br>
  <p>
  “Policy conclave 23”, our flagship event  showcased Videsh Neeti, an enthralling case study competition that unfolded as a captivating exploration of geopolitical issues. The 3 distinctive rounds, a blend of intellect and strategy , featured an insightful quiz, challenging participants to navigate the complex landscape of international relations. Creativity and diplomacy came to the forefront in the video submission round. Where participants addressed and presented solutions to pressing global challenges. The event culminated in an exhilarating spot round, testing participants ability to think on their feet. Policy Conclave 23 through Videsh Neeti not only fostered a deep understanding of foreign policy but also celebrated the art of diplomacy in an ever changing world.
  </p>
  <img src="https://picsum.photos/400/250" alt="Description of your image" /><br></br>
  </div><center><div><a href="register.html" className="register-button">
    Register Now
  </a></div></center>
</div>
</div></div>

      </> 
  );
};

export default Event;
